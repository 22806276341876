import { CommerceToolsApi } from '@grandvisionhq/commercetools'
import type { ProductDetailProvider } from '@grandvisionhq/products'

type SpecificationConfiguration = React.ComponentPropsWithoutRef<
  typeof ProductDetailProvider
>['specificationConfiguration']

export function getSpecifications(productType: string): SpecificationConfiguration | undefined {
  if (CommerceToolsApi.isFrames(productType)) {
    return [
      {
        labelKey: 'products.specifications.brand',
        attribute: 'brand',
      },
      {
        labelKey: 'products.specifications.frameShape',
        attribute: 'frameShape',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameColor',
        attribute: 'frameColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameMaterial',
        attribute: 'frameMaterial',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.templeType',
        attribute: 'templeType',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.articleNumber',
        attribute: 'opcoBackendId',
      },
    ]
  }

  if (CommerceToolsApi.isSunglasses(productType)) {
    return [
      {
        labelKey: 'products.specifications.brand',
        attribute: 'brand',
      },
      {
        labelKey: 'products.specifications.frameColor',
        attribute: 'frameColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.lensColor',
        attribute: 'lensColor',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.frameShape',
        attribute: 'frameShape',
        isLocalised: true,
      },
      {
        labelKey: 'products.sunglasses.features.polarized',
        attribute: 'polarized',
      },
      {
        labelKey: 'products.specifications.prescriptionGlass',
        attribute: 'prescriptionGlass',
      },
      {
        labelKey: 'products.specifications.frameMaterial',
        attribute: 'frameMaterial',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.templeType',
        attribute: 'templeType',
        isLocalised: true,
      },
      {
        labelKey: 'products.specifications.articleNumber',
        attribute: 'opcoBackendId',
      },
      {
        labelKey: 'products.specifications.trend',
        attribute: 'trend',
      },
      {
        labelKey: 'products.specifications.roadworthiness',
        attribute: 'roadworthiness',
        labelPrefix: 'products.specifications.roadworthiness.',
      },
    ]
  }
}
